import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation, useParams } from "react-router-dom";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import DefaultBanner from "../components/DefaultBanner";
import ApplicationCard from "../components/ApplicationCard";
import { useServices } from "utils/requests";
import appConfig from "../config/apps.yml";
import ServicesCard from "components/ServicesCard";


const Applications = () => {
  // Code CS ajouté
  const { id } = useParams();
  const [service, setService] = useState(null);
  const { isError, isLoading, isSuccess, data: stacResponse } = useServices();	
  const [applications, setApplications] = useState([]);
  
  useEffect(() => {
    if (isSuccess) {
      const service = stacResponse.features;
            if (service) {
        setApplications(stacResponse.features)
      } else {
      }
    }
  }, [id, stacResponse, isSuccess]);


  const banner = (
    <DefaultBanner>
      <h1>Applications</h1>
      <p>
        The EO Catalogue puts global-scale environmental monitoring
        capabilities in the hands of scientists, developers, and policy makers,
        enabling data-driven decision making. Learn about some of the applications
        our partners are building as part of the EO Catalogue.
      </p>
    </DefaultBanner>
  );


  const appsList = applications.map(app => {
    return <ServicesCard key={`card-${app.id}`} app={app} />;
  });

  // const storageList = appConfig.map(app => {
  //   return <ApplicationCard key={`card-${app.title}`} app={app} />;
  // });


  

  return (
    <Layout bannerHeader={banner} isShort>
      <SEO title="Applications" />
      <div className="layout-container">
        <div className="layout-row grid-content">{appsList}</div>
        {/* <div className="layout-row grid-content">{storageList}</div> */}
      </div>
    </Layout>
  );
};

export default Applications;
