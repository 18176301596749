import { BBox, Feature, GeoJsonProperties, MultiPolygon, Polygon } from "geojson";
import bboxToPolygon from "@turf/bbox-polygon";
import union from "@turf/union";
import { IPcCollection, IStacCollection } from "types/stac";
import collections from "config/datasets.yml";

export const spatialExtentToMultipolygon = (bbox: number[][]) => {
  const polys = bbox.map(box => bboxToPolygon(box as BBox));
  
  const multiPoly = polys.reduce<Feature<
  Polygon | MultiPolygon,
  GeoJsonProperties
  > | null>((unionedPoly, currPoly) => {
    if (unionedPoly) {
      return union(unionedPoly.geometry, currPoly.geometry);
    }
    // base case
    return currPoly;
  }, null);
  
  return multiPoly;
};

export const isValidExplorer = (collection: IStacCollection) => {
  // By default, all collections with at least one GeoTIFF data-role item_asset
  // are renderable. Collections can be restricted from explorer via a hideInExplorer
  // attribute in the dataset config.
  if (collection.item_assets) {
    const hasCOG = !!Object.values(collection.item_assets).find(a =>
      a.type?.toLowerCase().includes("geotiff")
      );
      const isHidden = Boolean(collections[collection.id]?.hideInExplorer);
      const hasCollectionTileJson = !!Object.values(collection.assets || {}).find(a =>
        a.roles?.includes("tiles")
        );
        
        return (hasCOG || hasCollectionTileJson) && !isHidden;
      }
      return false;
    };
    
    
    
    export const filterCollections = (collection: IPcCollection, filters:any) => {
      let match = true
      
      const keywordsAsString = collection.keywords.join(' ').toLowerCase()
      const providerNamesAsString = collection.providers?.map(provider=> provider.name).join(' ').toLowerCase() || ''
      const temporalStartDate = collection.extent?.temporal?.interval[0][0] ? new Date(collection.extent.temporal.interval[0][0])
                                                                           : new Date("1000-01-01T00:00:00Z")
      const temporalEndDate = collection.extent?.temporal?.interval[0][1] ? new Date(collection.extent.temporal.interval[0][1])
                                                                         : new Date()
      
      Object.keys(filters).map(key => {
        switch (key) {
          case 'filterText':
            if (filters[key]){
              const textValid = [collection.id, 
                keywordsAsString,
                collection.title,
              ].join('').toLowerCase()
              match = match && textValid.includes(filters[key].toLowerCase())
            }
            break;
          
          case 'keywords':
            if (filters[key])
            match = match && keywordsAsString.includes(filters[key].toLowerCase())
            break;
          
          
          case 'provider':
            if (filters[key])
            match = match && providerNamesAsString.includes(filters[key].toLowerCase())
            break;

          case 'providerRole':
            // const providerRolesAsString = collection.providers?.map(provider=> provider.roles.join(' ')).join(' ').toLowerCase() || ''
            const providerFiltered = collection.providers?.filter(provider=> 
                                    provider.name.toLowerCase().includes(filters['provider'].toLowerCase())) || []
            
            const providerRolesAsString = providerFiltered.map(provider=> provider.roles.join(' ')).join(' ').toLowerCase() || ''
            debugger

            
            if (filters[key])
              match = match && providerRolesAsString.includes(filters[key].join(' ').toLowerCase())
            break;

          case 'lat':
            const filterLat = filters[key].split(',')
            if (filters[key] && filterLat.length === 2){
              try {
                const lat = collection['cube:dimensions']?.lat?.extent || [9999, -9999]
                const filterLat0 = filterLat[0] ? parseInt(filterLat[0]) : -9999 
                const filterLat1 = filterLat[1] ? parseInt(filterLat[1]) : 9999 
                match = match && filterLat0 < lat[0]
                              && filterLat1 > lat[1]; 
              }catch (error){ 
                console.log(error)
              }
           
            }
            break;

          case 'lon':
            const filterLon = filters[key].split(',')
            if (filters[key] && filterLon.length === 2){
              try {
                const lon = collection['cube:dimensions']?.lon?.extent || [9999, -9999]
                const filterLon0 = filterLon[0] ? parseInt(filterLon[0]) : -9999 
                const filterLon1 = filterLon[1] ? parseInt(filterLon[1]) : 9999 
                match = match && filterLon0 < lon[0]
                              && filterLon1 > lon[1];
            }catch (error){ 
            console.log(error)
            }
          }
        
          break;

          case 'bbox':
            const filterBbox = filters[key].split(',')
            if (filters[key] && filterBbox.length === 4){
              try {
                const bbox = collection.extent?.spatial?.bbox[0] || [9999, 9999, -99999,-99999]
                const filterBbox0 = filterBbox[0] ? parseInt(filterBbox[0]) : -9999 
                const filterBbox1 = filterBbox[1] ? parseInt(filterBbox[1]) : -9999 
                const filterBbox2 = filterBbox[2] ? parseInt(filterBbox[2]) : 9999 
                const filterBbox3 = filterBbox[3] ? parseInt(filterBbox[3]) : 9999 

                match = match && filterBbox0 < bbox[0]
                              && filterBbox1 < bbox[1]
                              && filterBbox2 > bbox[2]
                              && filterBbox3 > bbox[3]
              }catch (error){ 
                console.log(error)
                }
            }
            break;

          case 'startDate':
            if (filters[key]){
              match = match && (filters[key].getTime() < temporalStartDate.getTime() ||
                                filters[key].getTime() < temporalEndDate.getTime() )
            }
            break;

          case 'endDate':
            if (filters[key]){
              match = match && (filters[key].getTime() > temporalStartDate.getTime() || 
                                filters[key].getTime() > temporalEndDate.getTime())
            }
            break;

          default:
          break;
          
          
        }
      });
      return match
    };
    