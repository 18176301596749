import { IconButton, IIconProps, IStackTokens, SearchBox, Stack } from "@fluentui/react";
import { useState } from "react";
import { CollectionFilter } from "types/stac";
import { CatalogFilterAdvanced } from "./Catalog.Filter.Advanced";

interface CatalogFilterProps {
  filter: CollectionFilter;
  onFilterChange?: any // (new_value: any, field: string) => void;
  advanced?: boolean
}

const bannerTokens: IStackTokens = {
  childrenGap: 20,
};

const chevronDown: IIconProps = { iconName: 'ChevronDown' };
const chevronUp: IIconProps = { iconName: 'ChevronUp' };


export const CatalogFilter: React.FC<CatalogFilterProps> = ({
  filter,
  onFilterChange,
  advanced
}) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const onClear = () =>{
    onFilterChange("", "filterText")
  }

  const onChangeFilterText = (e: any) =>{
    if (e?.type === 'input'){
      onFilterChange(e?.target.value, "filterText")
    }
  }

  return (
    <>
      {!advanced 
      ? 
        <SearchBox
          placeholder="Filter dataset"
          value={filter?.filterText}
          onChange={onChangeFilterText}
          onClear={onClear}
          data-cy="catalog-filter-input"
          styles={{ root: { width: "90%" } }}
        />
      :
        <Stack  tokens={bannerTokens} style ={{"width": "100%"}}>
          <Stack>
            <Stack horizontal>
              <SearchBox placeholder="Filter dataset"
                       value={filter?.filterText}
                       onChange={onChangeFilterText}
                       onClear={onClear}
                       data-cy="catalog-filter-input"
                       styles={{ root: { width: "90%" } }} />
              <IconButton iconProps={ toggle ? chevronUp : chevronDown } onClick={() => {setToggle(!toggle)} }/>
            </Stack>
          </Stack>
          {toggle && <CatalogFilterAdvanced filter={filter} onFilterChange={onFilterChange} />}
        </Stack>
      }
    </>
    
  );
};

