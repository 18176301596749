import React, { createContext, useState } from "react";
import Keycloak from "keycloak-js"
import { SIGN_IN_ERROR_MESSAGE, KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL, KEYCLOAK_CLIENT_SSO_PERSIST } from "utils/constants";

export type Session = {
  isLoggedIn: boolean;
  email: string;
  token: string | null;
};

type SessionContextType = {
  status: Session;
  logout: () => void;
  login: () => void;
};

const initialSession: Session = {
  isLoggedIn: false,
  email: "",
  token: null,
};

const initialContext: SessionContextType = {
  status: initialSession,
  logout: () => {},
  login: () => {},
};

const KEYCLOAK_TOKEN = "t";
const KEYCLOAK_REFRESH_TOKEN = "r";

const SessionContext = createContext<SessionContextType>(initialContext);

const checKeycloakCredential = () : [boolean, string] =>{
  let isloginenabled = true, error_message = "" 
  const errorMessageUrl = KEYCLOAK_URL ? "" : "- Keycloak Url\n"
  const errorMessageRealm = KEYCLOAK_REALM ? "" : "- Keycloak Realm\n"
  const errorMessageClientID = KEYCLOAK_CLIENT_ID ? "" : "- Keycloak ClientID\n"

  isloginenabled = !errorMessageUrl && !errorMessageRealm && !errorMessageClientID
  
  if (!isloginenabled)
    error_message = `${SIGN_IN_ERROR_MESSAGE}${errorMessageUrl}${errorMessageRealm}${errorMessageClientID}`

  return [isloginenabled, error_message]
}


export const SessionProvider: React.FC = ({ children }) => {
  const [session, setSession] = useState<Session>(initialSession);

  const [isloginenabled, error_message] = checKeycloakCredential()
  const context = {
    status: session,
    logout: () => {
      setSession(initialSession);
    
      if (KEYCLOAK_CLIENT_SSO_PERSIST) {
        keycloak?.logout();
      }

      sessionStorage.removeItem(KEYCLOAK_TOKEN);
      sessionStorage.removeItem(KEYCLOAK_REFRESH_TOKEN);
    },
    login: () => {
      if (!isloginenabled){
        alert(error_message);
      }
      else{
        if (keycloak && !keycloak.authenticated)
        keycloak.init({
          onLoad: "login-required",
          checkLoginIframe: false
        });
      }
    },
  };

  if (keycloak){
    if (!context.status.isLoggedIn)
    keycloak.init(
      document.location.hash.indexOf("state") >= 0
      ? { onLoad: "check-sso" }
      : {
        token: sessionStorage.getItem(KEYCLOAK_TOKEN) || "",
        refreshToken: sessionStorage.getItem(KEYCLOAK_REFRESH_TOKEN) || ""
      }).then(() => {
      if (keycloak?.authenticated) {
        // We're logged in. Update session context
        if (keycloak.token !== undefined)
        setSession({
          email: "",
          isLoggedIn: true,
          token: keycloak.token
        });
  
        sessionStorage.setItem(
          KEYCLOAK_TOKEN,
          keycloak.token || ""
        );
        sessionStorage.setItem(
          KEYCLOAK_REFRESH_TOKEN,
          keycloak.refreshToken || ""
        );
      }
    });
  
    // when the token expires
    keycloak.onTokenExpired = () => {
      console.log('token expired', keycloak?.token);
      keycloak?.updateToken(300)
      .then(() => {
        if (keycloak?.token !== undefined)
          setSession({
            email: "",
            isLoggedIn: true,
            token: keycloak.token
          })
          sessionStorage.setItem(
            KEYCLOAK_TOKEN,
            keycloak?.token || ""
          );
          sessionStorage.setItem(
            KEYCLOAK_REFRESH_TOKEN,
            keycloak?.refreshToken || ""
          );
      })
      .catch(() => context.login);
    }
  }

  return (
    <SessionContext.Provider value={context}>{children}</SessionContext.Provider>
  );
};


export const useSession = () => {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};


export let keycloak = KEYCLOAK_URL && KEYCLOAK_CLIENT_ID && KEYCLOAK_REALM? new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
}) : null;
