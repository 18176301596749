import React from "react";
import { Link, Text } from "@fluentui/react";
import ChevronLink from "./controls/ChevronLink";

const ServicesCard = ({ app }) => {
  const { assets, links, properties } = app;

  const describeLink = links.find((e) => e.rel === 'describes')

  return (
    <div className="api-datasource-item">
      <h2 style={{ color: "initial", marginTop: 0 }}>{properties.title}</h2>
      <Link href={describeLink && describeLink.href ? describeLink.href : ""} underline={false} style={{ textDecoration: "none" }}>
        <div className="responsive-container-wide">
          <img alt=""
            src={assets && assets.thumbnail && assets.thumbnail.href ? assets.thumbnail.href : ""}
            onError={(event) => event.target.src = './images/fallback-service-thumbnail.jpg'}
          />
        </div>
        <p style={{ color: "initial", marginBottom: 10, minHeight: 37 }}>
          {properties.description}
        </p>
      </Link>
      <Text block variant="medium" style={{ fontWeight: 600 }}>
        {links.map(({ title, href }) => {
          if (title) {
            console.log(href)
            return (
              <div key={title} style={{ paddingBottom: 15 }}>
                <ChevronLink href={href} label={title} />
              </div>
            );
          }
        })}
      </Text>
    </div>
  );
};

export default ServicesCard;
