import { useSession } from "components/auth/hooks/SessionContext";
import HeaderLink from "./components/HeaderLink";
import Feature from "components/Feature";
import Login from "components/auth/Login";
import Logout from "components/auth/Logout";
import {
  headerPipeStyle,
  innerHeaderStyle,
  logoImageStyle,
  logoLinkStyle,
  productNameStyle,
  rightAligned,
} from "./styles";
import { Stack, Text } from "@fluentui/react";
import { TAB_HUB_DISABLE } from "../../metis";
import { CatalogFilter } from "pages/Catalog2/Catalog.Filter";
import { HUB_URL } from "utils/constants";


interface CatalogBannerProps {
  filter?: any | undefined;
  onFilterChange?: any //(new_value: any, field: string)=> void;
}


export const HeaderBar: React.FC<CatalogBannerProps> = ({
  filter,
  onFilterChange
}) => {
  
  return (
    <Stack
      className={innerHeaderStyle}
      horizontal
      wrap
      verticalAlign="center"
      tokens={headerTokens}
    >
      <a
        className={logoLinkStyle}
        href="/"
        aria-label="EOCatalogue"
      >
        <img
          alt=""
          className={logoImageStyle}
          src="./images/eo-catalogue/eocatalogue-logo/eocatalogue-logo.png"
          role="presentation"
          aria-hidden="true"
        />
      </a>
      <a
        className={logoLinkStyle}
        href="https://www.csgroup.eu/"
        aria-label="EOCatalogue"
      >
        <img
          alt=""
          className={logoImageStyle}
          src="./images/cs/logo-CSGROUP.png"
          role="presentation"
          aria-hidden="true"
        />
      </a>
      <div className={headerPipeStyle}>|</div>
      <HeaderLink to="/">
        <Text block variant="large" className={productNameStyle}>
          EO Catalogue
        </Text>
      </HeaderLink>
      <HeaderLink isNav to="/explore">
        Explore
      </HeaderLink>
      <HeaderLink isNav to="/catalog">
        Data Catalog
      </HeaderLink>
      {!TAB_HUB_DISABLE ?
        (<HeaderLink external to={HUB_URL}>
          Hub
        </HeaderLink>)
        : ''
      }
      <HeaderLink isNav to="/applications">
        Applications
      </HeaderLink>
      <HeaderLink isNav to="/docs">
        Documentation
      </HeaderLink>
      <div className={rightAligned}>
        <Stack horizontal verticalAlign="center" tokens={authSectionTokens}>
          <CatalogFilter filter={filter} onFilterChange={onFilterChange} />
          <Feature name="login">
            <Login />
            <Logout />
          </Feature>
        </Stack>
      </div>
        
      
    </Stack>
  );
};

const headerTokens = { childrenGap: "10px 21px" };
const authSectionTokens = { childrenGap: 4 };
