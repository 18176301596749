import { Link, Text } from "@fluentui/react";
import { MAIL_CONTACT } from "metis";
import { store } from "pages/Explore/state/store";
import { Provider } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import ImageStrip from "./ImageStrip";

const BannerFooter = () => {
  return (
    <>
   
      {/* <ImageStrip /> */}
      
      <div className="home-footer-container">
        <div className="home-footer-grid grid-content">
          <Text
            block
            variant="xxLargePlus"
            className="home-footer-item"
            style={{ color: "#fff" }}
          >
            Building a global environmental network
          </Text>
          <div className="home-footer-item">
            <Text
              block
              variant="large"
              style={{
                color: "#fff",
                marginBottom: 20,
              }}
            >
              The EO Catalogue is only as strong as CS Group that
              is building applications on it.,{" "}
              <Link
                underline
                href={MAIL_CONTACT}
                style={{ color: "#fff", fontWeight: 600 }}
              >
                contact us
              </Link>
              .
            </Text>
            <Text
              block
              variant="large"
              style={{
                color: "#fff",
              }}
            >
              The EO Catalogue API is currently available in preview.
              If you're interested in developing on our platform,{" "}
              <RouterLink
                to="/account/request"
                style={{
                  color: "#fff",
                  fontWeight: 600,
                }}
              >
                request access now
              </RouterLink>
              .
              <p>
                Learn more about CS{" "}
                <Link
                  underline
                  href="https://www.csgroup.eu"
                  style={{ color: "#fff", fontWeight: 600 }}
                >
                </Link>
                .
              </p>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerFooter;
