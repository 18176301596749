import { useState } from "react";
import { ActionButton, getTheme } from "@fluentui/react";
import { useTimeoutFn } from "react-use";
import {
  FOOTER_ABOUT_ADS_DISABLE, FOOTER_ABOUT_ADS_HREF,
  FOOTER_CONTACT_DISABLE, FOOTER_CONTAC_HREF,
  FOOTER_MANAGE_COOKIE_DISABLE,
  FOOTER_PRIVACY_DISABLE, FOOTER_PRIVACY_HREF,
  FOOTER_SAFETY_DISABLE, FOOTER_SAFETY_HREF,
  FOOTER_SERVICE_STATUS_HREF, FOOTER_SITEMAP_DISABLE,
  FOOTER_SITEMAP_HREF, FOOTER_TERMS_OF_USE_DISABLE,
  FOOTER_TRADEMARKS_DISABLE,
  FOOTER_TRADEMARKS_HREF
} from "metis";

const Footer = ({ onGrid = true }) => {
  const theme = getTheme();
  // Allow users to manage their cookie consent preferences. Not all regions
  // require consent, and the cookie library will do a no-op if the consent dialog is
  // launched in a non-required region. So check for requirements before rendering the button.
  // Defaulting to true is primarily to satisfy the compliance test, to ensure
  // that manage button is rendered on first render, while the consent library
  // may still be loading.
  const [isConsentRequired, setIsConsentRequired] = useState(true);

  useTimeoutFn(() => {
    // Cookie consent is determined regionally. If it can't be determined, default
    // to requiring consent.
    const consent = window?.siteConsent?.isConsentRequired;
    setIsConsentRequired(consent === undefined ? true : consent);
  }, 100);

  const manageConsent = isConsentRequired ? (
    // Button to launch consent form, styled to mimic the UHF links
    <ActionButton
      onClick={() => window.siteConsent.manageConsent()}
      ariaDescription="Launch cookie consent form"
      style={{ fontSize: "11px", padding: "0", color: "#616161", height: "0" }}
    >
      Manage cookies
    </ActionButton>
  ) : null;

  const navClass = onGrid ? "grid-content" : "off-grid-content";

  // Ajout de code Metis
  return (
    <footer
      className="context-uhf"
      style={{
        borderTop: "1px solid",
        borderColor: theme.palette.neutralQuaternaryAlt,
      }}
    >
      <div className={`c-uhff-base ${navClass}`} style={{ paddingBottom: 2 }}>
        <nav aria-label="CS Group links">
          <ul
            className="c-list f-bare"
            data-m='{"cN":"Corp links_cont","cT":"Container","id":"c7c1c1m1r1a2","sN":7,"aN":"c1c1m1r1a2"}'
            style={{ margin: 0 }}
          >
            {!FOOTER_SITEMAP_DISABLE ? (
              <li id="c-uhff-footer_sitemap">
                <a
                  className="c-uhff-link"
                  href={FOOTER_SITEMAP_HREF}
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_Sitemap_nav","id":"n1c7c1c1m1r1a2","sN":1,"aN":"c7c1c1m1r1a2"}'
                >
                  Sitemap
                </a>
              </li>) : ''}
            {!FOOTER_CONTACT_DISABLE ? (
              <li id="c-uhff-footer_contactus">
                <a
                  className="c-uhff-link"
                  href={FOOTER_CONTAC_HREF}
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_ContactUs_nav","id":"n2c7c1c1m1r1a2","sN":2,"aN":"c7c1c1m1r1a2"}'
                >
                  Contact CS Group
                </a>
              </li>) : ''}

            {!FOOTER_PRIVACY_DISABLE ? (
              <li id="c-uhff-footer_privacyandcookies">
                <a
                  className="c-uhff-link"
                  href={FOOTER_PRIVACY_HREF}
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_PrivacyandCookies_nav","id":"n3c7c1c1m1r1a2","sN":3,"aN":"c7c1c1m1r1a2"}'
                >
                  Privacy{" "}
                </a>
              </li>) : ''}

            {!FOOTER_TERMS_OF_USE_DISABLE ? (
              <li id="c-uhff-footer_termsofuse">
                <a
                  className="c-uhff-link"
                  href="/terms"
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_TermsOfUse_nav","id":"n5c7c1c1m1r1a2","sN":5,"aN":"c7c1c1m1r1a2"}'
                >
                  Terms of use
                </a>
              </li>) : ''}

            {!FOOTER_TRADEMARKS_DISABLE ? (
              <li id="c-uhff-footer_trademarks">
                <a
                  className="c-uhff-link"
                  href={FOOTER_TRADEMARKS_HREF}
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_Trademarks_nav","id":"n6c7c1c1m1r1a2","sN":6,"aN":"c7c1c1m1r1a2"}'
                >
                  Trademarks
                </a>
              </li>) : ''}

            {!FOOTER_SAFETY_DISABLE ? (
              <li id="c-uhff-footer_safetyandeco">
                <a
                  className="c-uhff-link"
                  href={FOOTER_SAFETY_HREF}
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_SafetyAndEco_nav","id":"n7c7c1c1m1r1a2","sN":7,"aN":"c7c1c1m1r1a2"}'
                >
                  Safety &amp; eco
                </a>
              </li>) : ''}

            {!FOOTER_ABOUT_ADS_DISABLE ? (
              <li id="c-uhff-footer_aboutourads">
                <a
                  className="c-uhff-link"
                  href={FOOTER_ABOUT_ADS_HREF}
                  data-mscc-ic="false"
                  data-m='{"cN":"Footer_AboutourAds_nav","id":"n8c7c1c1m1r1a2","sN":8,"aN":"c7c1c1m1r1a2"}'
                >
                  About our ads
                </a>
              </li>) : ''}

            {!FOOTER_MANAGE_COOKIE_DISABLE && isConsentRequired && (
              <li className=" x-hidden" id="c-uhff-footer_managecookies">
                {manageConsent}
              </li>
            )}

            {!FOOTER_ABOUT_ADS_DISABLE ? (
              <li>
                <a
                  href={FOOTER_SERVICE_STATUS_HREF}
                  title="Check current Metis Geo health status and view past incidents."
                >
                  Service Status
                </a>
              </li>) : ''}

            <li className="x-hidden-focus">
              {" "}
              © CS Group {new Date().getFullYear()}
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
