import { HUB_URL } from "utils/constants";


//
// Properties to configure Tabs


//
export const TAB_HUB_DISABLE:boolean= HUB_URL === "" ;


//
// Properties to configure Footer
//
export const FOOTER_SITEMAP_DISABLE:boolean=false;
export const FOOTER_SITEMAP_HREF:string='https://www.csgroup.eu';

export const FOOTER_CONTACT_DISABLE:boolean=false;
export const FOOTER_CONTAC_HREF:string='https://www.csgroup.eu/fr/contact/';

export const FOOTER_PRIVACY_DISABLE:boolean=true;
export const FOOTER_PRIVACY_HREF:string='https://www.csgroup.eu/vie-privee/';

export const FOOTER_TERMS_OF_USE_DISABLE:boolean=false;

export const FOOTER_TRADEMARKS_DISABLE:boolean=false;
export const FOOTER_TRADEMARKS_HREF:string='';

export const FOOTER_SAFETY_DISABLE:boolean=false;
export const FOOTER_SAFETY_HREF:string='';

export const FOOTER_ABOUT_ADS_DISABLE:boolean=false;
export const FOOTER_ABOUT_ADS_HREF:string='';

export const FOOTER_SERVICE_STATUS_DISABLE:boolean=false;
export const FOOTER_SERVICE_STATUS_HREF:string='';

export const FOOTER_MANAGE_COOKIE_DISABLE:boolean=false;

//
// Other properties
//

export const PAGE_TERMS_TRUST_HREF='';
export const PAGE_TERMS_TERME_OF_USE_HREF='';

export const MAIL_CONTACT:string='';

export default {};