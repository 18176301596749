import { Stack, Text, TextField, 
  DatePicker, Dropdown, ITextStyles, IDropdownOption, IconButton, 
  IButtonStyles, IIconProps, getTheme } from "@fluentui/react";
  import { CollectionFilter } from "types/stac";
  
  
  interface CatalogFilterProps {
    filter: CollectionFilter ;
    onFilterChange: any // (new_value: any, field: string) => void;
  }
  
  
  export const initialFilter: CollectionFilter =  {
    filterText: "",
    bbox: "",
    keywords: "",
    variables: undefined,
    provider: undefined,
    providerRole: [],
    lat: "",
    lon: "",
    startDate: undefined,
    endDate: undefined
  } 
  
  const twoDigitFormat = new RegExp(/^[\s+-]?[0-9]+,[\s+-]?[0-9]+[\s]?$/);
  const fourDigitFormat = new RegExp(/^[\s+-]?[0-9]+(,[\s+-]?[0-9]+){3}[\s]?$/);
  
  const checkLonLanFormat = (value: string): string => {
    return !value ? '' : twoDigitFormat.test(value) ? '' : `Input should follow this format: +\- 999, +- 999`;
  };
  const checkSpatialFormat = (value: string): string => {
    return !value ? '' : fourDigitFormat.test(value) ? '' : `Input should follow this format: +\- 999, +- 999, +- 999, +- 999`;
  };
  
  
  
  export const CatalogFilterAdvanced: React.FC<CatalogFilterProps> = ({
    filter,
    onFilterChange
  }) => {
    
    const onProviderRoleChange = (_: React.FormEvent<HTMLDivElement>, 
      option: IDropdownOption | undefined): void => {
        if (option) {
          onFilterChange(option.selected 
            ? [...filter.providerRole, option.key as string] 
            : filter.providerRole.filter((key:any) => key !== option.key), "providerRole");
          }
        };
        
        
        return (
          <>
          {/* 1 Row: long, lat */}
          
          <Stack horizontal >
          <div className="filter-catalog-row">
          <TextField 
          label="Longitude" onGetErrorMessage={checkLonLanFormat} placeholder='___,___'
          value={filter?.lon}  
          onChange={(e:any) => onFilterChange(e?.target.value, "lon")}
          />
          </div>
          <div className="filter-catalog-row"> 
          <TextField 
          label="Latitude" onGetErrorMessage={checkLonLanFormat} placeholder='___,___'
          value={filter?.lat} 
          onChange={(e:any) => onFilterChange(e?.target.value, "lat")}/>
          </div>
          </Stack>
          
          
          {/* 2 Row: bbox, keywords */}
          <Stack horizontal >
          <div className="filter-catalog-row"> 
          
          <TextField 
          label="Bbox" onGetErrorMessage={checkSpatialFormat} placeholder='___,___,___,___'
          value={filter?.bbox} 
          onChange={(e:any) => onFilterChange(e?.target.value, "bbox")}/>
          </div>
          
          <div className="filter-catalog-row">  
          <TextField 
          label="Keywords" placeholder={"Filter Keywords"} 
          value={filter?.keywords} 
          onChange={(e:any) => onFilterChange(e?.target.value, "keywords")} />
          </div>
          </Stack>
          
          {/* 3 Row: start-time, end-time */}
          <Stack horizontal >
          <div className="filter-catalog-row">  
          <div className="filter-catalog-flex">
          <Text block as={"p"} styles={blurbStyles}> Start temporal date </Text>
          <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={()=>{onFilterChange(undefined, 'startDate')}} />
          </div>
          <DatePicker 
          placeholder="Select date..."
          value ={filter?.startDate} onSelectDate={(e:any)=> {onFilterChange(e, 'startDate')}}/>
          </div>
          
          <div className="filter-catalog-row">  
          <div className="filter-catalog-flex">
          <Text block as={"p"} styles={blurbStyles}> End temporal date </Text>
          <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={()=>{onFilterChange(undefined, 'endDate')}} />
          </div>
          <DatePicker 
          placeholder="Select date..."
          value ={filter?.endDate} onSelectDate={(e:any)=> {onFilterChange(e, 'endDate')}}/>
          </div>
          </Stack>
          
          {/* 4 Row: providers name, provider role  */}
          <Stack horizontal >
            <div className="filter-catalog-row filter-baseline">  
              <div style={{width: "100%"}}>
                <Text block as={"p"} styles={blurbStyles}> Provider </Text>
                <TextField  
                placeholder="Find provider's name"
                value={filter?.provider} 
                onChange={(e:any) => onFilterChange(e?.target.value, "provider")}/>
              </div>
            </div>
            
            <div className="filter-catalog-row">  
              <div className="filter-catalog-flex">
                <Text block as={"p"} styles={blurbStyles}> Provider's role </Text>
                <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                onClick={()=>{onFilterChange([], 'providerRole')}}/>
              </div>
              <Dropdown 
              placeholder="Select options"
              selectedKeys={filter?.providerRole}
              onChange={onProviderRoleChange}
              multiSelect
              options={DropdownControlledMultiExampleOptions} 
              disabled={!filter.provider}/>
              
            </div>
          </Stack>
          
          </>
          
          );
        };
        
        
        const DropdownControlledMultiExampleOptions = [
          { key: 'producer', text: 'Producer' },
          { key: 'licensor', text: 'Licensor' },
          { key: 'processor', text: 'Processor' },
          { key: 'host', text: 'Host' },
        ];
        
        const blurbStyles: ITextStyles = {
          root: {
            fontWeight: 500,
          },
        };   
        
        const cancelIcon: IIconProps = { iconName: "Cancel" };
        
        const theme = getTheme();
        const iconButtonStyles: IButtonStyles = {
          root: {
            color: theme.palette.neutralPrimary,
            marginLeft: "auto",
            marginTop: 4,
            marginRight: 2,
          },
          rootHovered: {
            color: theme.palette.neutralDark,
          },
        };