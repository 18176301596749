import { DefaultButton } from "@fluentui/react";
import { useSession } from "./hooks/SessionContext";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "utils/constants";

const Login: React.FC = () => {
  const isShown = KEYCLOAK_URL !== '' || KEYCLOAK_CLIENT_ID !== '' || KEYCLOAK_REALM !== ''
  let session = useSession();
  const login = (
    <>
     {isShown 
     ? <DefaultButton onClick={() => session.login()}>Sign in</DefaultButton>
     : null
     }
    </>
  );

  return !session.status.isLoggedIn ? login : null;
};

export default Login;
