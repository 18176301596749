import { DefaultButton } from "@fluentui/react";
import { useSession } from "./hooks/SessionContext";

const Logout: React.FC = () => {
  let session = useSession();
  const logout = (
    <>
      <DefaultButton onClick={() => session.logout()}>Sign out</DefaultButton>
    </>
  );

  return session.status.isLoggedIn ? logout : null;
};

export default Logout;
