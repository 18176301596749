import { IStackTokens, ITextStyles, Stack, Text } from "@fluentui/react";
import DefaultBanner from "components/DefaultBanner";
import { CollectionFilter } from "types/stac";
import { CatalogFilter } from "./Catalog.Filter";

interface CatalogBannerProps {
  filter: CollectionFilter;
  onFilterChange: any //(new_value: any, field: string)=> void;
}

export const CatalogBanner: React.FC<CatalogBannerProps> = ({
  filter,
  onFilterChange
}) => {
  return (
    <DefaultBanner>
      <h1>Data Catalog</h1>
      <Stack tokens={bannerTokens}>
        <Text block as={"p"} styles={blurbStyles}>
          The EO Catalogue Data Catalog includes petabytes of environmental
          monitoring data, in consistent, analysis-ready formats. All of the datasets
          below can be accessed via Azure Blob Storage, and can be used by developers
          whether you're working within or outside of our EO Catalogue Data Hub.
        </Text>
        <CatalogFilter filter={filter} onFilterChange={onFilterChange} advanced/>
      </Stack>
    </DefaultBanner>
  );
};

const bannerTokens: IStackTokens = {
  childrenGap: 20,
};

const blurbStyles: ITextStyles = {
  root: {
    fontWeight: 500,
  },
};
