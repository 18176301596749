import React from "react";

import SEO from "../components/Seo";
import Layout from "../components/Layout";
import { Link } from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, SelectionMode } from "@fluentui/react";

const Terms = () => {
  const token_tier_rows = [
    { key: "1", name: "Tier 1", value: "Up to 1 week" },
    { key: "2", name: "Tier 2", value: "Up to 6 months" },
  ];

  const token_tier_columns = [
    {
      key: "column1",
      name: "Participation Tier",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 200,
      isResizable: false,
    },
    {
      key: "column2",
      name: "Authorized Time for Token Use",
      fieldName: "value",
      minWidth: 100,
      maxWidth: 200,
      isResizable: false,
    },
  ];

  return (
    <Layout isShort>
      <SEO title="Terms of Use" />
    </Layout>
  );
};

export default Terms;
